import React, { useState } from "react"
import styled from 'styled-components'
import { mq } from '@stylesheets/settings'
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Video from "../../components/sections/video"
import Stage from "../../components/sections/stage"
import About from "../../components/sections/about"
import Gallery from "../../components/sections/gallery"
import ShareImage from "../../static/images/og-image.jpg"
import CaseVideo from '../../static/case/webby-case.mp4'


const WebbyAwardsPage = ({ intl }) => {

  const [badgeStyle, setBadgeStyle] = useState('visible');
  
  useScrollPosition(
    ({ prevPos, currPos }) => {
      var d = document.documentElement;
      var offset = d.scrollTop + window.innerHeight;
      var height = d.offsetHeight;
      const isVisible = offset < height;
      setBadgeStyle(isVisible ? 'visible' : 'hidden');
    },
    [badgeStyle]
  );

  return (
    <>
      <Layout>
        <SEO 
          title={intl.formatMessage({ id: "title" })} 
          meta={[
            {
              property: 'og:image:width',
              content: '1190',
            },
            {
              property: 'og:image:height',
              content: '630',
            },
            { 
              property: 'og:image', 
              content: `https://unhate-women.com${ShareImage}`
            }
          ]}  />
        <Stage copy={'An initiative against misogynistic hate speech and for more respect towards women.'} />
        <About 
          headline={{regular: 'Watch', bold: 'Casefilm'}}
          copy={'Violence against women – read by women'} />
        <Video headline={{
            regular: 'Watch', 
            bold: 'Casefilm'
          }} 
          copy={'Violence against women – read by women'} 
          isYT={false} 
          url={CaseVideo}
        />
        <About 
          headline={{regular: 'The', bold: 'Initiative'}} 
          copy={
            '<strong>Hate against women: language creates reality</strong></br></br>' +
            'Violence against women does not necessarily have to be physical; ' +
            'it can also be inflicted with words. As has been the case for '+
            'many years with German rap, which has dominated '+ 
            'the charts and promoted a Stone Age image of women.' + 

            '</br></br> <strong>#UNHATEWOMEN rocks the rap scene and social media</strong>' +

            '</br></br>Through a viral video campaign, #UNHATEWOMEN takes on the ' +
            'rappers with their own weapons: their lyrics. Read out by women ' +
            'and free from dazzling music videos and booming beats, only one '+
            'thing remains: pure misogyny. In order to reach the target group as '+ 
            'effectively as possible, individual quotations were placed as YouTube ' +
            'pre-rolls in front of the very music videos from which they originated. ' +
            'It was impossible to ignore them!' +
            '<br/><br/><strong>Start a movement and encourage discussion</strong></br></br>' + 
            'The campaign becomes a controversially discussed topic and the subject ' +
            'of nationwide reporting, thereby successfully increasing the pressure on politicians ' +
            'to take action: the German Federal Council passes the law against hate crimes on the Internet.' 
          }/>
        <Gallery noLinks={true}/>
        <About 
          copy={
            'All motifs show real quotes from songs of successful German rappers.'
          }/>
        <About 
          headline={{regular: 'Check it', bold: 'out'}} 
          copy={
            'Visit the campaigns original website: <a href="https://unhate-women.com/">unhate-women.com</a>'
        }/>
      </Layout>
    </>
  );
};

export default injectIntl(WebbyAwardsPage)
